import axios from 'axios';

import { PAGEID } from './i18nRosseta';
import { clearLocalStorageItems } from './storage';

const getCountry = () => window.location.pathname.split('/')[1].split('-')[0].toUpperCase();

const setQueryParams = (url, isCountryCodeRequired, country) => {
  const countryCode = country?.toUpperCase() || getCountry();
  const urlParam = url.includes('?')
    ? `${url}&countrycode=${countryCode}`
    : `${url}?countrycode=${countryCode}`;
  return isCountryCodeRequired ? urlParam : url;
};

export const setCookie = async () => {
  const url = `/ums/v1/auth/token/guest`;
  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    url
  };
  const res = await apiCall(apiArgs);
  return res;
};

export const refreshToken = async () => {
  const url = `/ums/v1/auth/refresh/cookies`;
  const apiArgs = {
    API_CALL: {
      method: 'POST',
      data: {
        source: PAGEID.FLIGHT_PATH
      }
    },
    url
  };
  const res = await apiCall(apiArgs);
  return res;
};

export default async function apiCall(payload, withCredentials = true) {
  const {
    API_CALL,
    data,
    url,
    headers,
    isCountryCodeRequired = true,
    extraPayload = {
      responseRequired: false,
      errorResponseRequired: false,
      hasBaseUrl: false,
      isFlightPath: false
    }
  } = payload;

  const API_URL = extraPayload.hasBaseUrl
    ? url
    : `${process.env.NEXT_PUBLIC_API_URL}${setQueryParams(url, isCountryCodeRequired, API_CALL?.data?.countryCode)}`;
  // Setting API parameters
  const apiParams = {
    ...API_CALL,
    ...data,
    headers,
    url: API_URL,
    withCredentials
  };

  try {
    // Make API call
    const apiResponse = await axios(apiParams);
    if (apiResponse) {
      // API call success
      return extraPayload.responseRequired ? apiResponse : apiResponse.data;
    }
  } catch (err) {
    if (err.response?.status === 401 && err.response?.data?.message === 'The incoming token has expired') {
      const response = await refreshToken();
      if (response) return apiCall(payload);
    }
    if (err.response?.status === 401) {
      const locale = window.location.pathname.split('/')[1];
      const param = window.location.pathname.split('/')[2];
      if (param !== 'login' && param === 'flight-path') {
        clearLocalStorageItems();
        window.location.replace(`/${locale}/login?unauthorized=true`);
      }
    }
    // API call failure
    return extraPayload.errorResponseRequired ? err.response : null;
  }
  return null;
}
