/* eslint-disable max-len */
const fixedKeys = ['sonic_cart_page', 'metadata', 'footer', 'contact', 'about_us',
  'account_mgmnt_navbar', 'account_address_details', 'login_page', 'navbar', 'orientation', 'notifications', 'product_data', 'review_widget_story', 'new_landing_page'];

// Add pageId for lokalize configuration in  getstaic props on each page for adding lokalize keys;
//  if there is no lokalise key put an empty array respective for that page, so that only fixed keys will loaded

export const PAGEID = {
  ABOUT: 'about',
  ACCOUNT_ORDERS: 'account_orders',
  ACCOUNT_DETAILS: 'account_details',
  ADD_ADDRESS: 'add_address',
  ADDRESSES: 'addresses',
  AFTER_VISIT: 'after_visit',
  ALIGNER_PAYMENTS: 'aligner_payments',
  BOOKACALL: 'bookacall',
  CART: 'cart',
  CHECKOUT_LOGIN: 'checkout_login',
  CHECKOUT_PAGE: 'checkout_page',
  CHECKOUT_PAGE_TYPE: 'checkout_page_type',
  CLINIC_SIGNIN: 'clinic_signin',
  CONFIRM_NUMBER: 'confirm_number',
  CONFIRM_SLOTS: 'confirm_slots',
  CONFIRM_SLOTS_WAITING: 'confirm_slots_waiting',
  CONFIRMATION: 'confirmation',
  CONSULTATION: 'consultation',
  CONSUMER_PROTECTION_POLICY: 'consumer_protection_policy',
  CSATSURVEYCLINIC: 'csatSurveyClinic',
  CSATSURVEYNPS: 'csatSurveyNps',
  CSATSURVEYSALES: 'csatSurveySales',
  DENTIST: 'dentist',
  EDIT_ADDRESS: 'edit_address',
  ERROR_PAGE: 'error_page',
  EXPECT_VISIT: 'expect_visit',
  FAQS: 'faqs',
  FLIGHT_PATH: 'flight_path',
  HITRUST: 'hitrust',
  HITRUST_STATUS: 'hitrust_status',
  HOME: 'home',
  HOW_ZENYUM_WORKS: 'how_zenyum_works',
  INVISIBLE_BRACES: 'invisible_braces',
  KLOOK_LANDING: 'klook_landing',
  LOADER: 'loader',
  LOGIN: 'login',
  ORDER_SUMMARY: 'order_summary',
  ORDERS: 'orders',
  PAYMENT_METHODS: 'payment_methods',
  PAYMENT_SUCCESS: 'payment_success',
  PAYMENTS: 'payments',
  PREPARE_VISIT: 'prepare_visit',
  PRIVACY: 'privacy',
  REFER_A_FRIEND: 'refer_a_friend',
  REFERRALS: 'referrals',
  REFERRALS_MECHANISM: 'referrals_mechanism',
  REFUND_POLICY: 'refund_policy',
  RESET: 'reset',
  RESET_PASSWORD: 'reset_password',
  RETAINERS: 'retainers',
  SALES: 'sales',
  SELECT_CLINIC: 'select_clinic',
  SHOPALL: 'shopall',
  SIGN_UP: 'sign_up',
  SLOT_SELECTION: 'slot_selection',
  SPECIFIED_COMMERCIAL_TRANSACTIONS: 'specified_commercial_transactions',
  THANKYOU_REVIEW: 'thankyou_review',
  TOOTHPASTE_AND_MOUTHWASH: 'toothpaste_and_mouthwash',
  TREATMENT_PLAN: 'treatment_plan',
  WARRANTY_ACTIVATION: 'warranty_activation',
  WARRANTY_ACTIVATION_HOW_TO_FIND_SERIAL_NUMBER: 'warranty_activation_how_to_find_serial_number',
  WARRANTY_ACTIVATION_SERIALNUMBER: 'warranty_activation_serialnumber',
  WARRANTY_ACTIVATION_SUCCESS: 'warranty_activation_success',
  WARRANTY_ACTIVATION_WARRANTY_DEVICE_DETAILS: 'warranty_activation_warranty_device_details',
  WATERFLOSSERPRO_DETAILS: 'waterflosserpro_details',
  WATERFLOSSERPRO_TIPS: 'waterflosserpro_tips',
  WEBSITE_TERMS_AND_CONDITIONS: 'website_terms_and_conditions',
  WELCOME: 'welcome',
  YOTPO_TEST: 'yotpo_test',
  ZENFRESH_BREATHSHIELD_DETAILS: 'zenfresh_breathshield_details',
  ZENFRESH_BUNDLE_DETAILS: 'zenfresh_bundle_details',
  ZENFRESH_TOOTHPASTE_DAY: 'zenfresh_toothpaste_day',
  ZENFRESH_TOOTHPASTE_DETAILS: 'zenfresh_toothpaste_details',
  ZENFRESH_TOOTHPASTE_NIGHT: 'zenfresh_toothpaste_night',
  ZENYUM_FESTIVE_GIFT: 'zenyum_festive_gift',
  ZENYUM_FLOSS_DETAILS: 'zenyum_floss_details',
  ZENYUM_REFILLS: 'zenyum_refills',
  ZENYUM_SONIC: 'zenyum_sonic',
  ZENYUMBRIGHT_TEETH_WHITENING_DETAILS: 'zenyumbright_teeth_whitening_details',
  ZENYUMBRIGHT_TEETH_WHITENING_STRIPS: 'zenyumbright_teeth_whitening_strips',
  ZENYUMFLOSS: 'zenyumfloss',
  ZENYUMFRESH: 'zenyumfresh',
  ZENYUMFRESH_STORY: 'zenyumfresh_story',
  ZENYUMSONIC_BRUSH_HEAD: 'zenyumsonic_brush_head',
  ZENYUMSONIC_DETAILS: 'zenyumsonic_details',
  ZENYUMSONIC_ELECTRIC_TOOTHBRUSH: 'zenyumsonic_electric_toothbrush',
  ZENYUMSONIC_GENTLE_BRUSH_HEAD: 'zenyumsonic_gentle_brush_head',
  ZENYUMSONIC_STORY: 'zenyumsonic_story',
  ZENYUMSONICGO_BRUSH_HEAD: 'zenyumsonicgo_brush_head',
  ZENYUMSONICGO_DETAILS: 'zenyumsonicgo_details'
};

const i18nPageKeys = {
  warranty_activation: ['warranty_activation'],
  warranty_activation_serialnumber: ['warranty_activation'],
  warranty_activation_success: ['warranty_activation'],
  warranty_activation_how_to_find_serial_number: ['warranty_activation'],
  warranty_activation_warranty_device_details: ['warranty_activation'],
  payment_methods: ['aligner_payments_summary'],
  order_summary: ['order_summary_page', 'cart_page'],
  specified_commercial_transactions: ['commercial_law'],
  after_visit: ['clinic_selection'],
  confirm_number: ['clinic_selection'],
  confirm_slots_waiting: ['clinic_selection'],
  confirm_slots: ['clinic_selection'],
  confirmation: ['clinic_selection'],
  expect_visit: ['clinic_selection'],
  prepare_visit: ['clinic_selection'],
  select_clinic: ['clinic_selection'],
  clinic_signin: ['clinic_selection'],
  slot_selection: ['clinic_selection'],
  welcome: ['clinic_selection'],
  error_page: ['notfound_page', 'shopall_page', 'flight_path'],
  about: ['about_revamp'],
  account_details: ['account_details'],
  account_orders: ['account_orders', 'order_summary_page'],
  add_address: ['address_page'],
  addresses: ['address_page'],
  aligner_payments: ['aligner_payments_summary'],
  bookacall: [],
  cart: ['cart_page', 'coupon_errors'],
  checkout_login: [],
  checkout_page_type: ['checkout_page', 'order_summary_page', 'cart_page'],
  checkout_page: ['checkout_page', 'cart_page'],
  consultation: [],
  consumer_protection_policy: [],
  csatSurveyClinic: ['csat_survey'],
  csatSurveyNps: ['csat_survey'],
  csatSurveySales: ['csat_survey'],
  dentist: ['dentist_page', 'faqs'],
  edit_address: ['address_page'],
  faqs: ['faqs', 'faq_get_your_free_assessment_btn', 'faq_ready_to_get_started', 'start_zenyum_journey'],
  hitrust_status: [],
  hitrust: [],
  how_zenyum_works: ['zenyum_birthday'],
  home: ['home_page_new', 'new_landing_page'],
  invisible_braces: ['aligners_page', 'aligner_category_page', 'faqs'],
  klook_landing: ['klook_landing', 'cart_page'],
  loader: [],
  login: ['login_page', 'home_page_new'],
  orders: ['account_orders'],
  payment_success: ['aligner_payments_summary'],
  payments: ['aligner_payments_summary'],
  privacy: ['privacy'],
  refer_a_friend: ['referrer'],
  referrals_mechanism: ['referrer'],
  referrals: ['referral'],
  refund_policy: ['refund_policy_faq', 'refund_policy_page'],
  reset_password: ['login_page', 'account_details'],
  reset: ['login_page'],
  retainers: ['retainer'],
  shopall: ['review_widget_product', 'shopall_page'],
  sign_up: ['login_page', 'account_details'],
  thankyou_review: ['thankyou_review'],
  toothpaste_and_mouthwash: ['toothpaste_mouthwash_story', 'storypage_nsr_section'],
  waterflosserpro_details: ['waterflosser_pro_page', 'review_widget_product', 'waterflosser_pro_page', 'cart_page', 'error_messages'],
  waterflosserpro_tips: ['waterflosser_pro_tips_page', 'review_widget_product', 'cart_page'],
  website_terms_and_conditions: ['terms_and_conditions'],
  yotpo_test: [],
  zenfresh_breathshield_details: ['zenyum_fresh_breathshield', 'review_widget_product', 'cart_page'],
  zenfresh_bundle_details: ['zenyum_fresh_bundle', 'review_widget_product', 'cart_page'],
  zenyum_floss_details: ['zenyum_fresh_floss', 'review_widget_product', 'cart_page'],
  zenfresh_toothpaste_day: ['zenyum_fresh_toothpaste', 'error_messages', 'cart_page'],
  zenfresh_toothpaste_details: ['zenyum_fresh_toothpaste', 'review_widget_product', 'cart_page'],
  zenfresh_toothpaste_night: ['zenyum_fresh_toothpaste', 'review_widget_product', 'cart_page'],
  zenyum_festive_gift: ['zenyum_festive_gift', 'review_widget_product', 'cart_page'],
  zenyum_refills: ['sonic_refill_story', 'toothbrush_story', 'storypage_nsr_section', 'cart_page'],
  zenyumbright_teeth_whitening_details: ['whitening_details_page', 'review_widget_product', 'cart_page'],
  zenyumbright_teeth_whitening_strips: ['whitening_page', 'cart_page'],
  zenyumfloss: ['floss_story', 'cart_page'],
  zenyumfresh_story: ['zenyum_fresh', 'review_widget_story', 'faqs'],
  zenyumfresh: [],
  zenyumsonic_brush_head: ['sonic_cart_page_faq', 'review_widget_product', 'hoolah_pay_text', 'cart_page'],
  zenyumsonic_details: ['review_widget_product', 'sonic_cart_page_faq', 'cart_page'],
  zenyumsonic_electric_toothbrush: ['storypage_nsr_section', 'toothbrush_story', 'cart_page'],
  zenyumsonic_gentle_brush_head: ['sonic_gentle_brushhead', 'review_widget_product', 'cart_page'],
  zenyumsonic_story: ['sonic_toothbrush', 'sonic_story_bundle', 'faqs', 'cart_page'],
  zenyum_sonic: [],
  zenyumsonicgo_brush_head: ['sonicgo_brushhead_page', 'review_widget_product', 'cart_page'],
  zenyumsonicgo_details: ['sonicgo_details_page', 'review_widget_product', 'cart_page'],
  flight_path: ['flight_path', 'retainer'],
  treatment_plan: ['flight_path']
};

const getTranslationKeys = (pageId) => {
  const keys = [...i18nPageKeys[pageId], ...fixedKeys];
  return keys;
};

export default getTranslationKeys;
